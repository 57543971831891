<template>
  <div>
    <div :key="authStatus" class="container mt-5" v-if="authStatus">
      <!-- Data Tables for Call and Put sides -->
      <div id="dataTable">
        <div>
          <div class="d-flex align-items-center justify-content-between mb-4">
            <h1>CALL side</h1>
            <div>
              <label for="callSound" style="margin-right: 5px">Call Notification Sound :</label>
              <select id="callSound" v-model="selectedCallSound" @change="saveCallSound">
                <option v-for="sound in availableSounds" :key="sound" :value="sound">{{ sound.substring(0,
                  sound.lastIndexOf('.')) }}</option>
              </select>
            </div>
          </div>
          <DataTable side="CALL" :latestData="latestData" :selectedLimit="selectedCallLimit" :limits="callLimits"
            @update:selectedLimit="saveCallLimit" />
        </div>
        <div class="d-flex align-items-center justify-content-center mb-4">
          <label for="number-select">Select a size:</label>
          <select v-model="selectedNumberCall" id="number-select">
            <option v-for="number in numbers" :key="number" :value="number">
              {{ number }}
            </option>
          </select>
          <b-button class="mx-2">Buy</b-button>
          <b-button class="mx-2">Sell</b-button>
          <b-button class="mx-2">Cancel All</b-button>
          <b-button class="mx-2">Close All</b-button>
          <label for="number-select">Stop Loss:</label>
          <select v-model="stopLossCall" id="number-select">
            <option v-for="number in stopLoss" :key="number" :value="number">
              {{ number }}
            </option>
          </select>
        </div>
        <div>
          <div class="d-flex align-items-center justify-content-between mb-4">
            <h1>PUT side</h1>
            <div>
              <label for="putSound" style="margin-right: 5px">Put Notification Sound :</label>
              <select id="putSound" v-model="selectedPutSound" @change="savePutSound">
                <option v-for="sound in availableSounds" :key="sound" :value="sound">{{ sound.substring(0,
                  sound.lastIndexOf('.')) }}</option>
              </select>
            </div>
          </div>
          <DataTable side="PUT" :latestData="latestData" :selectedLimit="selectedPutLimit" :limits="putLimits"
            @update:selectedLimit="savePutLimit" />
        </div>
        <div class="d-flex align-items-center justify-content-center mb-4">
          <label for="number-select">Select a size:</label>
          <select v-model="selectedNumberPut" id="number-select">
            <option v-for="number in numbers" :key="number" :value="number">
              {{ number }}
            </option>
          </select>
          <b-button class="mx-2">Buy</b-button>
          <b-button class="mx-2">Sell</b-button>
          <b-button class="mx-2">Cancel All</b-button>
          <b-button class="mx-2">Close All</b-button>
          <label for="number-select">Stop Loss:</label>
          <select v-model="stopLossPut" id="number-select">
            <option v-for="number in stopLoss" :key="number" :value="number">
              {{ number }}
            </option>
          </select>
        </div>
      </div>

      <!-- Video components -->
      <StreamVideo />
      <DataVideo />
      <FilterData />
      <!-- Audio player for notifications -->
      <audio ref="audioPlayer"></audio>
    </div>

    <div class="auth-container" v-if="!authStatus">
      <AuthLogin :authStatus="authStatus" :handleAuthStatus="handleAuthStatus" />
    </div>
  </div>
</template>

<script>
import socketIo from '@/plugins/socket';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import DataTable from './DataTable.vue';
import DataVideo from './DataVideo.vue';
// import AuthLogin from './AuthLogin.vue';
import StreamVideo from './StreamVideo.vue';
import FilterData from './FilterData.vue';
import VueCookie from 'vue-cookie';
import AuthLogin from './AuthLogin.vue';

// Initialize toast notifications
const $toast = useToast();

export default {
  components: {
    DataTable,
    DataVideo,
    StreamVideo,
    FilterData,
    AuthLogin
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      latestData: null,
      socket: null,
      notification: null,
      selectedPutLimit: '0.00',
      selectedCallLimit: '0.00',
      putLimits: this.generateLimits(),
      callLimits: this.generateLimits(),
      availableSounds: [
        "notification_01.mp3",
        "notification_02.mp3",
        "notification_03.mp3",
        "notification_04.mp3",
        "notification_05.mp3",
        "notification_06.mp3",
        "notification_07.mp3",
        "notification_08.mp3",
        "notification_09.mp3",
        "notification_10.mp3",
        "notification_11.mp3",
        "notification_12.wav",
        "notification_13.wav"
      ],
      selectedCallSound: 'notification_12.wav',
      selectedPutSound: 'notification_13.wav',
      selectedNumberCall: 10,
      selectedNumberPut: 30,
      stopLossCall: 2,
      stopLossPut: 5,
      numbers: this.generateNumbers(),
      stopLoss: this.generateStopLoss(),
      authStatus: true,
    };
  },
  mounted() {
    const notification = this.$route.query.notification;
    this.notification = notification === 'false' ? false : true;
    this.connectSocketIO();
    this.requestNotificationPermission();
    this.selectedCallSound = VueCookie.get('callSound') || 'notification_12.wav';
    this.selectedPutSound = VueCookie.get('putSound') || 'notification_13.wav';
    this.selectedNumberCall = 10;
    this.selectedNumberPut = 30;
    this.stopLossCall = 2;
    this.stopLossPut = 5;
    // this.userValidate()
  },
  methods: {
    handleAuthStatus(value) {
      this.authStatus = value
    },

    async userValidate() {
      try {
        if (!this.authStatus && VueCookie.get('authToken') !== null) {
          const url = `${process.env.VUE_APP_BACKEND_URL}/api/getUserByToken`;

          try {
            const response = await fetch(url, {
              method: 'GET', // Set method to POST
              headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${VueCookie.get('authToken')}`
              },
            });

            if (response.ok) {
              const data = await response.json();
              console.log('Login successful', data);
              this.handleAuthStatus(true)
            } else {
              console.error('Failed to login:', response.status);
            }
          } catch (error) {
            console.error('Error during login request:', error);
          }
        } else {
          this.handleAuthStatus(false)
        }
      } catch (error) {
        console.error('Authentication error:', error);
      }
    },

    // Generate limits for selection
    generateLimits() {
      return Array.from({ length: 21 }, (_, i) => (i * 0.5).toFixed(2));
    },

    // Save and emit new Put limit
    savePutLimit(limit) {
      this.selectedPutLimit = limit;
      this.socket && this.socket.emit('changePutLimit', limit);
    },

    // Save and emit new Call limit
    saveCallLimit(limit) {
      this.selectedCallLimit = limit;
      this.socket && this.socket.emit('changeCallLimit', limit);
    },

    // Save selected call sound to cookie
    saveCallSound() {
      this.saveSound('callSound', this.selectedCallSound);
      this.playSound(this.selectedCallSound);
    },

    // Save selected put sound to cookie
    savePutSound() {
      this.saveSound('putSound', this.selectedPutSound);
      this.playSound(this.selectedPutSound);
    },

    saveSound(type, sound) {
      try {
        // Check if the selected sound is the same as the other sound type
        const otherType = type === 'callSound' ? 'putSound' : 'callSound';
        const otherSound = VueCookie.get(otherType);

        // Store the previous sound before making changes
        const previousSound = VueCookie.get(type);

        if (sound === otherSound) {
          VueCookie.set(type, otherSound, { expires: '12h' });
          // Revert the selected sound to the previous sound
          this[type] = previousSound;

          alert(`The selected sound is already used for ${otherType}. Please choose a different sound.`);
          return; // Prevent saving the same sound
        }
        // Save the sound to the cookie
        VueCookie.set(type, sound, { expires: '12h' });
        console.log(`${type} sound saved successfully`);

      } catch (error) {
        console.error(`Failed to save ${type} sound:`, error);
      }
    },

    playSound(sound) {
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer) {
        audioPlayer.src = `/assets/${sound}`;
        audioPlayer.load();
        audioPlayer.play().catch(error => console.error('Error playing sound:', error));
      }
    },

    // Request permission for browser notifications
    requestNotificationPermission() {
      if ('Notification' in window) {
        Notification.requestPermission().then(permission => {
          console.log(`Notification permission ${permission}.`);
        });
      } else {
        console.log('This browser does not support notifications.');
      }
    },

    // Send browser notification
    sendNotification(title, message, type) {
      if ('Notification' in window && Notification.permission === 'granted') {
        const notification = new Notification(title, { body: message });
        notification.onclick = event => event.preventDefault();

        const audioSrc = type === 'call' ? `/assets/${this.selectedCallSound}` : `/assets/${this.selectedPutSound}`;
        new Audio(audioSrc).play().catch(error => console.error('Error playing notification sound:', error));

        console.log('type', type, audioSrc);
      } else {
        console.log('Notifications are not permitted or supported.');
      }
    },

    // Show toast notification with cooldown
    makeToast(type, message) {
      $toast.open({
        message,
        type: 'default',
        duration: 3000,
        dismissible: true,
        queue: true,
      });

      this.sendNotification(type === 'call' ? 'Call Difference' : 'Put Difference', message, type);

      const cookieName = type === 'call' ? 'notShowCall' : 'notShowPut';
      VueCookie.set(cookieName, 'true', { expires: new Date(new Date().getTime() + process.env.VUE_APP_NOTIFICATION_DELAY * 1000) });
    },

    // Handle polygon data received from socket
    handlePolygonData(data) {
      const { callaskPriceDifference, putaskPriceDifference } = data;
      // Check if the cookie to prevent notification is set
      const notShowCall = VueCookie.get('notShowCall');
      const notShowPut = VueCookie.get('notShowPut');
      if (this.shouldShowToast('call', callaskPriceDifference) && !notShowCall && this.notification) {
        this.makeToast('call', `Call side difference reached ${this.selectedCallLimit}`);
      }

      if (this.shouldShowToast('put', putaskPriceDifference) && !notShowPut && this.notification) {
        this.makeToast('put', `Put side difference reached ${this.selectedPutLimit}`);
      }
    },

    // Determine if a toast should be shown
    shouldShowToast(type, priceDifference) {
      const limit = type === 'call' ? this.selectedCallLimit : this.selectedPutLimit;
      return limit !== '0.00' && Math.max(priceDifference, 0) === Number(limit);
    },

    // Connect to Socket.IO
    connectSocketIO() {
      if (this.authStatus) {
        this.socket = socketIo

        this.socket.on('connect', () => {
          console.log('Socket.IO connection established.');
          this.socket.emit('message', 'Hello server, send me polyData!');
        });

        this.socket.on('polygonData', data => {
          this.latestData = data;
          this.handlePolygonData(data);
        });

        this.socket.on('callLimit', data => {
          if (this.selectedCallLimit !== data) {
            console.log('Changed call limit:', this.selectedCallLimit, data);
            this.selectedCallLimit = data;
          }
        });

        this.socket.on('putLimit', data => {
          if (this.selectedPutLimit !== data) {
            console.log('Changed put limit:', this.selectedPutLimit, data);
            this.selectedPutLimit = data;
          }
        });

        this.socket.on('disconnect', () => {
          console.log('Socket.IO connection closed.');
        });

        this.socket.on('error', error => {
          console.error('Socket.IO error:', error);
        });
      }
    },

    generateNumbers() {
      let numbers = [];
      for (let i = 10; i <= 100; i += 10) {
        numbers.push(i);
      }
      return numbers;
    },
    generateStopLoss() {
      let numbers = [];
      for (let i = 1; i <= 10; i += 1) {
        numbers.push(i);
      }
      return numbers;
    }
  },
};
</script>

<style scoped>
.auth-container {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  height: 100vh;
  /* Full viewport height */
  width: 100%;
  /* Full viewport width */
}

.form-wrapper {
  max-width: 500px;
  /* Optional: Set a maximum width for the form */
  width: 100%;
  /* Ensure the form takes full width up to max-width */
  padding: 20px;
  /* Optional: Add padding around the form */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Optional: Add shadow for better visibility */
  border-radius: 8px;
  /* Optional: Add rounded corners */
  background-color: #fff;
  /* Optional: Set background color */
}
</style>
