<template>
    <div class="d-flex justify-content-center align-items-center vh-100 ">
        <div>
            <h1 class="text-center mb-4">Login to SPX</h1>
            <b-card class="p-1 mx-auto" style="min-width: 340px;">
                <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                    <b-form-group id="input-group-1" label="Email address:" label-for="input-1">
                        <b-form-input id="input-1" v-model="form.email" type="email" placeholder="Enter email"
                            required></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-2" label="Password:" label-for="input-2">
                        <b-form-input id="input-2" v-model="form.password" type="password" placeholder="Enter password"
                            required></b-form-input>
                    </b-form-group>

                    <div class="d-flex justify-content-end">
                        <b-button type="reset" variant="danger" class="me-2">Reset</b-button>
                        <b-button type="submit" variant="primary">Submit</b-button>
                    </div>
                </b-form>

                <!-- Registration condition -->
                <div v-if="!isRegistered" class="text-center mt-3">
                    <p>Don't have an account? <a href="#" @click="onRegister">Register here</a></p>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import VueCookie from 'vue-cookie';
import { useToast } from 'vue-toast-notification';

// Initialize toast notifications
const $toast = useToast();
export default {
    props: {
        authStatus: Boolean,
        handleAuthStatus: Function,
    },
    data() {
        return {
            form: {
                email: '',
                password: ''
            },
            show: true,
            isRegistered: false // This determines if the user is registered or not
        };
    },
    methods: {
        async onSubmit(event) {
            event.preventDefault();
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/login`;
            const loginData = this.form

            try {
                const response = await fetch(url, {
                    method: 'POST', // Set method to POST
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                    },
                    body: JSON.stringify(loginData), // Convert the data to JSON string
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('Login successful', data);
                    this.handleAuthStatus(true)
                    VueCookie.set('authToken', data?.token, { expires: '12h' })
                    $toast.open({
                        message: "Authenticate Successfully!",
                        type: 'default',
                        duration: 3000,
                        dismissible: true,
                        queue: true,
                    });
                } else {
                    console.error('Failed to login:', response.status);
                    this.handleAuthStatus(false)
                    VueCookie.set('authToken', null, { expires: '12h' });
                    $toast.open({
                        message: "Authenticate Failed!",
                        type: 'default',
                        duration: 3000,
                        dismissible: true,
                        queue: true,
                    });
                }
            } catch (error) {
                console.error('Error during login request:', error);
            }
        },
        onReset(event) {
            event.preventDefault();
            // Reset our form values
            this.form.email = '';
            this.form.password = '';
            this.show = false;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        onRegister() {
            alert("Registration form will be shown here.");
            this.isRegistered = true;
        }
    }
};
</script>