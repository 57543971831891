<template>
  <div id="streamContainer" class="container mt-5">
    <h1 class="mb-5">Live Screenshot Viewer</h1>
    <div v-if="loading">
      <p>Loading images...</p>
    </div>
    <div id="streamBox" v-else>
      <img
        :src="imageRoute(currentImageIndex)"
        :alt="`Live Image ${currentImageIndex}`"
      />
      <input
        type="range"
        @input="handleRangeChange"
        :min="firstRangeIndex"
        :max="lastImageIndex"
        v-model="currentImageIndex"
      />
      <div id="streamController">
      <button
        @click="handleBack"
        :disabled="isFirstImage"
        style="margin: 0 10px;"
      >
        &lt;
      </button>
      <!-- <button
        @click="togglePausePlay"
        style="margin: 0 10px;"
      >
        {{ isPaused ? "Play" : "Pause" }}
      </button> -->
      <button
        @click="handleGoLive"
        :disabled="isLive"
        style="margin: 0 10px;"
      >
        Go Live
      </button>
      <button
        @click="handleForward"
        :disabled="isLastImage"
        style="margin: 0 10px;"
      >
        &gt;
      </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import socketIo from '@/plugins/socket';

const currentImageIndex = ref(0);
const firstRangeIndex = ref(0);
const lastImageIndex = ref(0);
const isLive = ref(true);
const isPaused = ref(false);
const intervalRef = ref(null);
const loading = computed(() => lastImageIndex.value === 0);

const isFirstImage = computed(() => currentImageIndex.value === firstRangeIndex.value);
const isLastImage = computed(() => currentImageIndex.value === lastImageIndex.value);

const imageRoute = (index) => `${process.env.VUE_APP_BACKEND_URL}/stream/screenshot${index}.png`;

const handleScreenshot = (data) => {
  if (data && data.url) {
    lastImageIndex.value = data.index;
    firstRangeIndex.value = data.startIndex;

    if (isLive.value && !isPaused.value) {
      currentImageIndex.value = data.index;
    }
  }
};

const startAutoPlay = () => {
  clearInterval(intervalRef.value);
  intervalRef.value = setInterval(() => {
    if (!isPaused.value && !isLive.value) {
      console.log(currentImageIndex.value)
      currentImageIndex.value++;
      if (currentImageIndex.value >= lastImageIndex.value) {
        handleGoLive();
      }
    }
  }, 1000);
};

const handleRangeChange = () => {
  isLive.value = false;
  isPaused.value = false;
  startAutoPlay();
};

const handleGoLive = () => {
  isLive.value = true;
  isPaused.value = false;
  currentImageIndex.value = lastImageIndex.value;
  clearInterval(intervalRef.value);
};

const handleBack = () => {
  if (currentImageIndex.value > firstRangeIndex.value) {
    currentImageIndex.value--;
    isLive.value = false;
    startAutoPlay();
  }
};

const handleForward = () => {
  if (currentImageIndex.value < lastImageIndex.value) {
    currentImageIndex.value++;
    isLive.value = false;
    startAutoPlay();
  }
};

// const togglePausePlay = () => {
//   isPaused.value = !isPaused.value;
//   if (isPaused.value) {
//     clearInterval(intervalRef.value);
//   } else {
//     startAutoPlay();
//   }
// };

const handleKeyDown = (event) => {
  if (event.key === "ArrowLeft") {
    handleBack();
  } else if (event.key === "ArrowRight") {
    handleForward();
  }
};

onMounted(() => {
  socketIo.on('screenshot', handleScreenshot);
  window.addEventListener("keydown", handleKeyDown);
});

onUnmounted(() => {
  socketIo.off('screenshot', handleScreenshot);
  window.removeEventListener("keydown", handleKeyDown);
  clearInterval(intervalRef.value);
});
</script>

<style scoped>
#streamContainer {
  width: 100%;
  padding: 0;
}

#streamBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#streamBox img {
  width: 80%;
  min-width: 300px;
  height: auto;
  border: 1px solid grey;
}

#streamBox input {
  width: 100%;
  margin-top: 20px;
}

#streamController {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
</style>
