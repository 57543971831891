<template>
  <div class="mt-5">
    <!-- Existing template code -->
    <div id="directoryHeader" class="align-items-center justify-content-between">
      <h2>Search Video</h2>
      <div id="videoSearchBarBox" class="d-flex align-items-center justify-content-between float-end">
        <div id="videoSearchBarTop" v-if="isSearchBarVisible">
          <select class="form-select" aria-label="Select Date" v-model="selectedDate">
            <option disabled value="">Select Date</option>
            <option v-for="(date, index) in dateOptions" :key="index" :value="date">{{ date }}</option>
          </select>
  
          <select class="form-select" aria-label="Select Time" v-model="selectedTime" :disabled="!selectedDate">
            <option disabled value="">Select Time</option>
            <option v-for="(time, index) in timeOptions" :key="index" :value="time">{{ time }}</option>
          </select>
  
          <select class="form-select" aria-label="Select Video" v-model="selectedVideo" :disabled="!selectedTime">
            <option disabled value="">Select Video</option>
            <option v-for="(video, index) in videoOptions" :key="index" :value="video">{{ getFileNameFromUrl(video.videoUrl) }}</option>
          </select>
        </div>
        <button class="btn btn-primary" @click="toggleSearchBar">
          <svg v-if="!isSearchBarVisible" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
          </svg>
          <svg v-if="isSearchBarVisible" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
          </svg>
        </button>
      </div>
    </div>

    <hr />

    <div style="display: none; margin-bottom: 100px">
      <div id="videoDirectory">
        <div v-for="(dateEntry, index) in directoryData" :key="index">
          <div v-for="(files, date) in dateEntry" :key="date">
            <h3 @click="toggleFolder(date)">{{ date }}</h3>
            <div v-if="isFolderOpen(date)" style="margin-left: 20px;">
              <div v-for="(fileObject, index) in files" :key="index">
                <div v-for="(fileEntries, time) in fileObject" :key="time">
                  <h4 @click="toggleSubFolder(date, time)">{{ time }}</h4>
                  <ul v-if="isSubFolderOpen(date, time)">
                    <li v-for="(file, fileIndex) in fileEntries" :key="fileIndex">
                      <span>
                        {{ getFileNameFromUrl(file.videoUrl) }}
                        <a :href="file.downloadUrl" download>Download</a>
                        <p @click="playVideo(process.env.VUE_APP_BACKEND_URL + file.videoUrl, false)">Play</p>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="videoPlayerContainer">
      <div id="videoPlayerBox" v-show="isVideoPlayerVisible">
        <div id="videoHeader">
          <div class="d-flex align-items-center">
            <h2 id="videoTitle">{{ currentVideoTitle }}</h2>
            <a :href="videoDownloadUrl">Download</a>
          </div>
          <span @click="closeVideo">X</span>
        </div>
        <video id="videoPlayer" ref="videoPlayer" />
        <div id="video-controls">
          <div>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="30" 
              height="30" 
              fill="currentColor" 
              class="bi bi-play" 
              viewBox="0 0 18 18" 
              @click="playVideo"
              v-if="!isPlaying"
            >
              <path d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z"/>
            </svg>
        
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="30" 
              height="30" 
              fill="currentColor" 
              class="bi bi-pause" 
              viewBox="0 0 18 18" 
              @click="pauseVideo"
              v-if="isPlaying"
            >
              <path d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5"/>
            </svg>
          </div>
          
          <div @click="toggleMute">
            <svg v-if="isMuted" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-volume-mute" viewBox="0 0 18 18">
              <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06M6 5.04 4.312 6.39A.5.5 0 0 1 4 6.5H2v3h2a.5.5 0 0 1 .312.11L6 10.96zm7.854.606a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0"/>
            </svg>

            <svg v-if="!isMuted" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-volume-down" viewBox="0 0 18 18">
              <path d="M9 4a.5.5 0 0 0-.812-.39L5.825 5.5H3.5A.5.5 0 0 0 3 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 9 12zM6.312 6.39 8 5.04v5.92L6.312 9.61A.5.5 0 0 0 6 9.5H4v-3h2a.5.5 0 0 0 .312-.11M12.025 8a4.5 4.5 0 0 1-1.318 3.182L10 10.475A3.5 3.5 0 0 0 11.025 8 3.5 3.5 0 0 0 10 5.525l.707-.707A4.5 4.5 0 0 1 12.025 8"/>
            </svg>
          </div>
      
          <div id="playback-bar-container">
            <input 
              type="range" 
              id="playback-bar" 
              ref="playbackBar" 
              min="0" 
              max="100" 
              step="0.1" 
              @input="seekVideo" 
            />
            <span>{{ formatTime(currentTime) }} / {{ formatTime(duration) }}</span>
          </div>

          <select v-model="localSelectedSpeedLimit" @click="handleSpeedLimitChange(localSelectedSpeedLimit)" class="mr-2">
            <option v-for="limit in speedlimits" :key="limit" :value="limit">{{ limit + 'x' }}</option>
          </select>

          <span @click="skipBackward()">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-skip-backward-circle" viewBox="0 0 18 18">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M11.729 5.055a.5.5 0 0 0-.52.038L8.5 7.028V5.5a.5.5 0 0 0-.79-.407L5 7.028V5.5a.5.5 0 0 0-1 0v5a.5.5 0 0 0 1 0V8.972l2.71 1.935a.5.5 0 0 0 .79-.407V8.972l2.71 1.935A.5.5 0 0 0 12 10.5v-5a.5.5 0 0 0-.271-.445"/>
            </svg>
          </span>
          <span  @click="skipForward()">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-fast-forward-circle" viewBox="0 0 18 18">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M4.271 5.055a.5.5 0 0 1 .52.038L8 7.386V5.5a.5.5 0 0 1 .79-.407l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 8 10.5V8.614l-3.21 2.293A.5.5 0 0 1 4 10.5v-5a.5.5 0 0 1 .271-.445"/>
            </svg>
          </span>

          <select v-model="localSelectedLimit" @click="handleLimitChange(localSelectedLimit)" class="mr-2">
            <option v-for="limit in limits" :key="limit" :value="limit">{{ limit + 's' }}</option>
          </select>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, defineComponent, onMounted, onUnmounted } from 'vue';
import socketIo from '@/plugins/socket';

export default defineComponent({
  name: 'DirectoryViewer',
  data() {
    return {
      directoryData: [],
      selectedDate: '',
      selectedTime: '',
      selectedVideo: '',
      dateOptions: [],
      timeOptions: [],
      videoOptions: [],
      localSelectedLimit: '1',
      localSelectedSpeedLimit: '1',
      limits: this.generateLimits(10),
      speedlimits: this.generateLimits(3),
    };
  },
  setup() {
    const openFolders = reactive({});
    const openSubFolders = reactive({});
    const currentVideoTitle = ref('');
    const videoDownloadUrl = ref('');
    const isVideoPlayerVisible = ref(false);
    const isSearchBarVisible = ref(false);

    const videoStreamUrl = ref('')
    const videoPlayer = ref(null);
    const playbackBar = ref(null);
    const currentTime = ref(0);
    const duration = ref(0);
    const isPlaying = ref(false);
    const isMuted = ref(false);
    const skipValue = ref('1'); 
    const speedValue = ref('1'); 

    // Store the last known time when pausing
    let lastKnownTime = 0;

    // Toggle folder open/close
    const toggleFolder = (date) => {
      openFolders[date] = !openFolders[date];
    };

    // Toggle subfolder open/close
    const toggleSubFolder = (date, time) => {
      if (!openSubFolders[date]) {
        openSubFolders[date] = {};
      }
      openSubFolders[date][time] = !openSubFolders[date][time];
    };

    // Toggle search bar visibility
    const toggleSearchBar = () => {
      isSearchBarVisible.value = !isSearchBarVisible.value;
    };

    // Toggle mute/unmute
    const toggleMute = () => {
      const video = videoPlayer.value;
      if (video) {
        video.muted = !isMuted.value;
        isMuted.value = !isMuted.value;
      }
    };

    // Get file name from URL
    const getFileNameFromUrl = (url) => url ? url.substring(url.lastIndexOf('/') + 1) : '';

    // Check if a folder is open
    const isFolderOpen = (date) => !!openFolders[date];

    // Check if a subfolder is open
    const isSubFolderOpen = (date, time) => openSubFolders[date] && !!openSubFolders[date][time];

    // Play video and set appropriate source
    const playVideo = (url, action = true) => {
      console.log('Attempting to play video from URL:', url);
      const video = videoPlayer.value;
      console.log("url", url)
      if (video) {
        const sourceUrl = url
        if (sourceUrl) {
          videoStreamUrl.value = sourceUrl
          video.src = sourceUrl;
          currentVideoTitle.value = getFileNameFromUrl(sourceUrl);
          videoDownloadUrl.value = sourceUrl.replace('/stream/', '/download/');
          video.currentTime = action ? lastKnownTime : 0; // Resume from where it was paused
          video.playbackRate = Number(speedValue.value)

          // Clear any existing error messages
          video.onerror = () => {
            console.error('Error: Failed to load video source.');
          };

          video.play().catch(error => {
            console.error('Playback failed:', error);
          });

          isVideoPlayerVisible.value = true;
          isPlaying.value = true;
        } else {
          console.error('Error: Video source URL is empty.');
        }
      } else {
        console.error('Error: Video player element not found.');
      }
    };

    // Pause video and store current time
    const pauseVideo = () => {
      const video = videoPlayer.value;
      if (video) {
        videoStreamUrl.value = video.src;
        lastKnownTime = video.currentTime; // Store the current time
        video.pause();
        isPlaying.value = false;
      }
    };

    // Close video player
    const closeVideo = () => {
      isVideoPlayerVisible.value = false;
    };

    // Skip backward by `skipValue` seconds
    const skipBackward = () => {
        console.log(skipValue.value);
        const video = videoPlayer.value;
        if (video) {
            video.currentTime = Math.max(0, video.currentTime - Number(skipValue.value));
        }
    };

    // Skip forward by `skipValue` seconds
    const skipForward = () => {
        console.log(skipValue.value);
        const video = videoPlayer.value;
        if (video) {
            video.currentTime = Math.min(video.duration, video.currentTime + Number(skipValue.value));
        }
    };

    // Update skipValue when the limit changes
    const handleLimitChange = (newValue) => {
        skipValue.value = newValue;
    };

    const handleSpeedLimitChange = (newValue) => {
      pauseVideo()
      speedValue.value = newValue
      playVideo(videoStreamUrl.value, true)
    }

    // Seek video based on playback bar value
    const seekVideo = () => {
      const video = videoPlayer.value;
      const playback = playbackBar.value;
      if (video && playback) {
        const newTime = (playback.value / 100) * video.duration;
        video.currentTime = newTime;
      }
    };

    // Update playback bar based on video current time
    const updatePlaybackBar = () => {
      const video = videoPlayer.value;
      const playback = playbackBar.value;
      if (video && playback) {
        const percent = (video.currentTime / video.duration) * 100;
        playback.value = percent;
        currentTime.value = video.currentTime;
        duration.value = video.duration;
      }
    };

    // Handle keyboard shortcuts for video playback
    const handleKeydown = (event) => {
      // console.log('event', event.key)
      if (event.key === 'ArrowLeft') {
        document.getElementById('videoPlayer').focus();
        isVideoPlayerVisible.value && skipBackward();
      } else if (event.key === 'ArrowRight') {
        document.getElementById('videoPlayer').focus();
        isVideoPlayerVisible.value && skipForward();
      } else if (event.key === ' ') { // Space key
        event.preventDefault();
        if (isPlaying.value) {
          pauseVideo();
        } else {
          playVideo(videoStreamUrl.value, true); // Play from current video source
        }
      }
    };

    // Format time in MM:SS
    const formatTime = (seconds) => {
      if (seconds === null || seconds === undefined) return '00:00';
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    onMounted(() => {
      const video = videoPlayer.value;
      if (video) {
        document.addEventListener('keydown', handleKeydown);
        video.addEventListener('timeupdate', updatePlaybackBar);
        video.addEventListener('loadedmetadata', updatePlaybackBar);
        video.addEventListener('volumechange', () => {
          isMuted.value = video.muted;
        });
        
        video.addEventListener('play', () => {
          isPlaying.value = true;
          videoStreamUrl.value = '';
        });

        video.addEventListener('pause', () => {
          isPlaying.value = false;
          videoStreamUrl.value = video.src;
        });

        document.addEventListener('click', (event) => {
          if (event.target === video) {
            if (video.paused) {
              video.play();
            } else {
              video.pause();
            }
          }
        });
      }
    });

    onUnmounted(() => {
      document.removeEventListener('keydown', handleKeydown);
      const video = videoPlayer.value;
      if (video) {
        video.removeEventListener('timeupdate', updatePlaybackBar);
        video.removeEventListener('loadedmetadata', updatePlaybackBar);
      }
    });

    return {
      openFolders,
      openSubFolders,
      currentVideoTitle,
      videoStreamUrl,
      videoDownloadUrl,
      isVideoPlayerVisible,
      toggleFolder,
      toggleSubFolder,
      toggleMute,
      isFolderOpen,
      isSubFolderOpen,
      playVideo,
      pauseVideo,
      closeVideo,
      getFileNameFromUrl,
      isSearchBarVisible,
      toggleSearchBar,
      skipBackward,
      skipForward,
      videoPlayer,
      playbackBar,
      seekVideo,
      currentTime,
      duration,
      formatTime,
      isPlaying,
      isMuted,
      handleLimitChange,
      handleSpeedLimitChange
    };
  },
  watch: {
    selectedDate(newDate) {
      if (newDate) {
        const dateEntry = this.directoryData.find(entry => Object.keys(entry)[0] === newDate);
        if (dateEntry) {
          this.timeOptions = dateEntry[newDate].map(item => Object.keys(item)[0])
          this.selectedTime = '';
          this.selectedVideo = '';
          this.videoOptions = [];
        }
      } else {
        this.timeOptions = [];
        this.selectedTime = '';
        this.selectedVideo = '';
        this.videoOptions = [];
      }
    },
    selectedTime(newTime) {
      if (newTime) {
        const dateEntry = this.directoryData.find(entry => Object.keys(entry)[0] === this.selectedDate);
        if (dateEntry) {
          const fileObject = dateEntry[this.selectedDate].map((item) => item[newTime]).filter((item) => item !== null && item !== undefined)[0];
          if (fileObject) {
            this.videoOptions = fileObject;
            this.selectedVideo = '';
          }
        }
      } else {
        this.videoOptions = [];
        this.selectedVideo = '';
      }
    },
    selectedVideo(newVideo) {
      console.log(newVideo)
      if (newVideo) {
        this.playVideo(process.env.VUE_APP_BACKEND_URL + newVideo.videoUrl, false);
        document.getElementById('videoPlayer').focus();
      }
    }
  },
  mounted() {
    this.connectSocketIO();
  },
  methods: {
    generateLimits(upto) {
      return Array.from({ length: upto }, (_, i) => (i + 1));
    },

    connectSocketIO() {
      this.socket = socketIo;
      this.socket.on('directoryData', (data) => {
        this.directoryData = data?.dateDirectory || [];

        // console.log('directoryData', this.selectedDate, this.selectedTime)
        // console.log('directoryData', data?.dateDirectory)

        this.dateOptions = this.directoryData.map(entry => Object.keys(entry)[0]) || [];

        if(this.selectedDate) {
          const dateEntry = data?.dateDirectory.find(entry => Object.keys(entry)[0] === this.selectedDate);
          if (dateEntry) {
            this.timeOptions = dateEntry[this.selectedDate].map(item => Object.keys(item)[0])
          }
        }

        if(this.selectedTime) {
          const dateEntry = data?.dateDirectory.find(entry => Object.keys(entry)[0] === this.selectedDate);
          if (dateEntry) {
            const fileObject = dateEntry[this.selectedDate].map((item) => item[this.selectedTime]).filter((item) => item !== null && item !== undefined)[0];
            if (fileObject) {
              this.videoOptions = fileObject;
            }
          }
        }

      });
    },
  },
});
</script>

<style scoped>
h3, h4, a, p {
  cursor: pointer;
  font-size: medium;
  text-decoration: underline;
  color: blue;
}

h2 {
  font-size: 22px;
  margin-bottom: 0px;
  margin-right: 15px;
}

a {
  margin-left: 10px;
  margin-right: 10px;
}

p {
  margin-bottom: 0;
  padding-bottom: 0;
}

button {
  width: 40px;
}

svg {
  margin-bottom: 4px;
}

span {
  cursor: pointer;
  font-size: medium;
  display: flex;
}

#directoryHeader{
  display: flex;
}

#videoSearchBarTop {
  max-width: 500px;
  float: right;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-right: 15px;
}

#videoDirectory {
  width: 450px;
}

#videoPlayerContainer {
  width: 100%;
}

#videoPlayerBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#videoHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-right: 15px;
}

#videoPlayer {
  width: 100%;
  min-width: 300px;
  height: auto;
  border: 1px solid grey;
  padding: 5px;
}

#video-controls {
  display: flex;
  position: relative;
  top: -60px;
  align-items: center;
  width: 98%;
  height: auto;
  padding: 10px;
  background-color: rgba(53, 53, 53, 0.663);
}

#video-controls svg {
  margin: 0px;
}

#video-controls select {
  width: 50px;
  margin: 0px 10px 5px 5px;
}

#playback-bar-container {
  display: flex;
  width: 90%;
  align-items: center;
  margin-right: 10px;
}

#playback-bar {
  flex-grow: 1;
  margin: 0 10px;
}

@media (max-width: 850px) {
  #videoPlayerBox {
    width: calc(100vw - 30px);
    position: absolute;
    top: 10%;
    right: 15px;
    padding-left: 15px;
  }

  #videoPlayer {
    border: 1px solid grey;
    padding: 0px;
  }
}

@media (max-width: 600px) {
  #directoryHeader{
    display: block;
    height: 70px;
  }

  #directoryHeader h2{
    margin-bottom: 10px;
  }

  #videoPlayerBox {
    padding-left: 0px;
  }
}
</style>
