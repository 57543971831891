<!-- src/components/DataTable.vue -->
<template>
  <div>
    <table class="table table-bordered table-striped">
      <thead class="thead-dark"></thead>
      <tbody>
        <tr v-if="latestData">
          <!-- <td>{{ convertToJamaicaTime(`${latestData.date} ${latestData.time}`, 'DD/MM/YYYY h:mm:ss a').split(' ')[0] }}</td>
            <td>{{ convertToJamaicaTime(`${latestData.date} ${latestData.time}`, 'DD/MM/YYYY h:mm:ss a').split(' ')[1] + " " + convertToJamaicaTime(`${latestData.date} ${latestData.time}`, 'DD/MM/YYYY h:mm:ss a').split(' ')[2] }}</td> -->
          <td>{{ latestData.date }}</td>
          <td>{{ latestData.time }}</td>
          <td>{{ side === 'CALL' ? latestData.call.toFixed(2) : latestData.putcall.toFixed(2) }}</td>
          <!-- <td>{{ side === 'CALL' ? 'CALL' : 'PUT' }}</td> -->
          <td>{{ 'CALL' }}</td>
          <td>{{ side === 'CALL' ? (latestData.callAskPrice ? latestData.callAskPrice.toFixed(2) : '-') :
            (latestData.putcallAskPrice ? latestData.putcallAskPrice.toFixed(2) : '-') }}</td>
          <td rowspan="2" style="text-align: center; vertical-align: middle;">
            {{ side === 'CALL' ? (latestData?.callaskPriceDifference !== null ?
              latestData?.callaskPriceDifference.toFixed(2) : '-') : (latestData?.putaskPriceDifference !== null ?
                latestData?.putaskPriceDifference.toFixed(2) : '-') }}
          </td>
        </tr>
        <tr v-if="latestData">
          <td>{{ latestData.dayName }}</td>
          <td>{{ latestData.baseValue.toFixed(2) }}</td>
          <td>{{ side === 'CALL' ? latestData.callput.toFixed(2) : latestData.put.toFixed(2) }}</td>
          <!-- <td>{{ side === 'CALL' ? 'CALL' : 'PUT' }}</td> -->
          <td>{{ 'PUT' }}</td>
          <td>{{ side === 'CALL' ? (latestData.callputAskPrice ? latestData.callputAskPrice.toFixed(2) : '-') :
            (latestData.putAskPrice ? latestData.putAskPrice.toFixed(2) : '-') }}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style="width: 200px">
            <span>Select {{ side }} Limit </span>
            <select :id="side.toLowerCase()" v-model="localSelectedLimit" @change="handleLimitChange">
              <option v-for="limit in limits" :key="limit" :value="limit">{{ limit }}</option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
  props: {
    side: String,
    latestData: Object,
    selectedLimit: String,
    limits: Array,
  },
  data() {
    return {
      localSelectedLimit: this.selectedLimit,
    };
  },
  watch: {
    selectedLimit(newVal) {
      this.localSelectedLimit = newVal;
    },
  },
  methods: {
    handleLimitChange(event) {
      event.preventDefault();
      this.$emit('update:selectedLimit', this.localSelectedLimit);
    },
    convertToJamaicaTime(value, format) {
      return moment(value).format(format);
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>